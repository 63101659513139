// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'bootstrap-icons/font/bootstrap-icons';

.nav-bar {
    background-color: #1f5151;
}
.nav-bar ul, .nav-bar li {
    list-style: none;
    padding: 0;
    margin: 0;
}
.nav-bar a, .nav-bar a:visited, .nav-bar a:hover {
    color: white;
}

.scheduled-meals-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
}